import { defineStore } from 'pinia'
import { computed, type ComputedRef, type Ref, ref } from 'vue'
import { route } from 'ziggy-js'
import axios from '@/axios'
import type DefaultWeekBackend from '@/Backend/models/DefaultWeekBackend'
import type TeamBackend from '@/Backend/models/TeamBackend'
import type UserBackend from '@/Backend/models/UserBackend'
import type CompanyBackend from '@/Backend/models/CompanyBackend'
import { availableLanguages, setLocale } from '@/i18n'
import { setDayJsLocale } from '@/dayjs'
import { useGetDefaultWeek } from '@/Query/defaultWeek'
import type UserHierarchy from '@/Frontend/UserHierarchy'

export const useUserStore = defineStore<
  'user',
  {
    initializeLocale: () => void
    fetchUser: () => void
    setUser: (user: UserBackend) => void
    user: Ref<UserBackend | null>
    lang: ComputedRef<string>
    timezone: ComputedRef<string>
    isLoading: Ref<boolean>
    isManager: ComputedRef<boolean>
    isManagerOf: (userId: number) => boolean
    teams: Ref<TeamBackend[] | null>
    hierarchy: Ref<UserHierarchy | null>
    defaultWeek: Ref<DefaultWeekBackend | null>
    isTeamManager: ComputedRef<boolean>
    isUserManager: ComputedRef<boolean>
    isOnlyManager: ComputedRef<boolean>
    personalTeam: ComputedRef<UserBackend[]>
    company: Ref<CompanyBackend | null>
    fetchDefaultWeek: (force: boolean) => void
  }
>('user', () => {
  const user = ref<UserBackend | null>(null)
  const teams = ref<TeamBackend[] | null>(null)
  const company = ref<CompanyBackend | null>(null)
  const hierarchy = ref<UserHierarchy | null>(null)
  const isLoading = ref(true)
  const defaultWeek = ref<DefaultWeekBackend | null>(null)

  const fetchUser = async () => {
    if (!user.value) {
      const { data } = await axios.get<{ data: UserBackend }>(route('api.v1.users.me'))
      if (data.data) {
        user.value = data.data
        company.value = data.data.company ?? null
        teams.value = data.data.teams ?? null
        defaultWeek.value = data.data.default_week ?? null
        hierarchy.value = {
          nPlus1: data.data.hierarchy?.nPlus1 ?? null,
          n: data.data.hierarchy?.n ?? [],
          nMinus1: data.data.hierarchy?.nMinus1 ?? []
        }
      }
    }
    await initializeLocale()
    isLoading.value = false
  }

  const fetchDefaultWeek = async (force = false) => {
    if (!defaultWeek.value || force) {
      const { data } = await useGetDefaultWeek()
      defaultWeek.value = data.value?.data ?? null
    }
  }

  const lang = computed(() => {
    const navigatorLanguage = navigator.language.substring(0, 2)
    return (
      user.value?.lang ??
      (availableLanguages.includes(navigatorLanguage) ? navigatorLanguage : 'fr')
    )
  })

  const timezone = computed(() => {
    return user.value?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const isUserManager = computed(() => {
    const nMinus1 = hierarchy.value?.nMinus1
    return nMinus1 !== undefined && nMinus1.length > 0
  })

  const isTeamManager = computed(() => {
    return teams.value?.some((t) => t.is_manager) ?? false
  })

  const isOnlyManager = computed(() => {
    return (
      isManager.value &&
      !user.value?.roles.some((r) => ['general_admin', 'workplace_admin'].includes(r.name))
    )
  })

  const isManager = computed(() => {
    return isUserManager.value || isTeamManager.value
  })

  const isManagerOf = (userId: number) => {
    return hierarchy.value?.nMinus1 ? hierarchy.value?.nMinus1.some((u) => u.id === userId) : false
  }

  const personalTeam = computed(() => {
    let data: UserBackend[] = []
    if (hierarchy.value) {
      data = data.concat(hierarchy.value.nMinus1.concat(hierarchy.value.n))
      if (hierarchy.value.nPlus1) {
        data.push(hierarchy.value.nPlus1 as UserBackend)
      }
      return data
    }
    return data
  })

  const initializeLocale = async () => {
    await setLocale(lang.value)
    setDayJsLocale(lang.value)
  }

  const setUser = (data: UserBackend) => {
    user.value = data
    return user.value
  }

  watch(timezone, (newTimezone, OldTimezone) => {
    if (newTimezone && newTimezone !== OldTimezone) {
      initializeLocale()
    }
  })

  watch(lang, (newLang, oldLang) => {
    if (newLang && newLang !== oldLang) {
      initializeLocale()
    }
  })

  return {
    initializeLocale,
    fetchUser,
    setUser,
    user,
    lang,
    timezone,
    isLoading,
    isOnlyManager,
    isManager,
    isManagerOf,
    teams,
    hierarchy,
    defaultWeek,
    isUserManager,
    isTeamManager,
    personalTeam,
    company,
    fetchDefaultWeek
  }
})
